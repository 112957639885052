<template>
    <div class="MariSlot">
        <div class="Game-Slot">
            <MenuBar></MenuBar>
            <div class="box-img">
                <div class="box-user-detail">
                    <strong>ตู้ Slot</strong>
                </div>
            </div>
            <div class="bg-overlay">
                <div class="full-width" id="main">
                    <MariSlotGame></MariSlotGame>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import MariSlotGame from '@/components/mari-slot/Game'
import MenuBar from '@/components/MenuBar'

export default {
    name: 'MariSlot',
    components: {
        MariSlotGame,
        MenuBar,
    },
}
</script>
<style src="@/assets/css/mari-slot.css" scoped></style>