<template>
    <div class="MariSlotNumberBox">
        <span>
            <ul style="justify-content: flex-end;">
                <li v-for="index in limit" :key="index">
                    {{getBetAmountByDigit(index - 1)}}
                </li>
            </ul>
        </span>
        <span class="bg">
            <ul style="justify-content: flex-end;">
                <li v-for="index in limit" :key="index">8</li>
            </ul>
        </span>
    </div>
</template>
<script>
export default {
    name: 'MariSlotNumberBox',
    props: [
        'number',
        'data_limit'
    ],
    computed: {
        limit() {
            return this.data_limit || 8
        }
    },
    methods: {
        getBetAmountByDigit(i) {
            let str = `${parseInt(this.number)}`
            const limit = this.limit
            const diff = limit - str.length
            for(let i = 0;i < diff;i++) {
                str = ' '+str
            }
            return str[i]
        },
    },
}
</script>
<style src="@/assets/css/mari-slot.css" scoped></style>