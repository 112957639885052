<template>
    <div class="MariSlotGame">
        <div class="container-fluid-hilo-game">
            <div class="wrap-content-games">
                <div class="wrapper-box-games wrapper-box-games-slots">
                    <div class="close-game"></div>
                    <div class="container-box-games-slots">
                        <div class="mainbox-games-slots">
                            <div class="mainbox-games-slots-info">
                                <div class="content-top-score">
                                    <div class="box-bonus-win">
                                        <small>Bonus-Win</small>
                                        <NumberBox :number="bonusWin"></NumberBox>
                                    </div>
                                    <div class="box-credit">
                                        <small>Credit</small>
                                        <NumberBox :number="credit"></NumberBox>
                                    </div>
                                </div>
                                <!--<button>Click to fade in boxes with a delay</button>-->
                                <div class="main-slots">
                                    <div class="box-main-slots">

                                        <div v-show="false" class="run-ac run-box" id="run" style="opacity: 0.8;"></div>
                                        <div v-show="false" class="run-ac run-box" id="run1" style="opacity: 0.7;"></div>
                                        <div v-show="false" class="run-ac run-box" id="run2" style="opacity: 0.6;"></div>
                                        <div v-show="false" class="run-ac run-box" id="run3" style="opacity: 0.5;"></div>
                                        <div v-show="false" class="run-ac run-box" id="run4" style="opacity: 0.4;"></div>

                                        <div v-show="false" class="ico-action active-point run-point"
                                            id="runpoint"
                                            style="top: 42px; left: 35px;"
                                        ></div>

                                        <div class="middnum">
                                            <!-- <span class="left middnum-1 counter-n"></span> -->
                                            <span :class="`left middnum-${middnumLeft} counter-n ${isMiddnumBlink}`"></span>
                                            <span :class="`right middnum-${middnumRight} counter-n ${isMiddnumBlink}`"></span>
                                            <span class="bg-num"></span>
                                            <span class="bg-num"></span>
                                        </div>

                                        <!--<div class="area-top box-2 active">-->
                                        <!--<div class="ico-action active-point "></div>-->
                                        <!--<div class="active-run blink"></div>-->
                                        <!--<div class="ico-slot sl-bell"></div>-->
                                        <!--</div>-->

                                        <div v-for="(panel, key) of playPanelArr" :key="key"
                                        :class="`${panel.rowClass}`">
                                            <div v-for="(item, itemKey) of panel.itemArr" :key="itemKey" 
                                            :class="`${item.areaClass} ${(activeIndex === item.i) ? 'active': ''} 
                                            ${isItemResult(item.i) ? 'active': ''} 
                                            ${isItemInExtra(item.i) ? 'active': ''}`">
                                                <div class="ico-action" :class="{
                                                    'active-point': activeIndex === item.i || 
                                                    isItemResult(item.i) || 
                                                    isItemInExtra(item.i)
                                                }"></div>
                                                <div 
                                                v-show="isItemResult(item.i) || isItemInExtra(item.i)" 
                                                class="active-run" 
                                                :class="{'blink-speed': isHaveWin && middnumState !== 'spin' || 
                                                item.slug === 'onemore' || 
                                                item.slug === 'onemore2'}"></div>
                                                <div v-show="isGameBusy">
                                                    <div v-show="activeIndex === item.i" class="active-run"></div>
                                                    <div v-show="activeIndexShadow1 === item.i" class="active-run" style="opacity: 0.7;"></div>
                                                    <div v-show="activeIndexShadow2 === item.i" class="active-run" style="opacity: 0.6;"></div>
                                                    <div v-show="activeIndexShadow3 === item.i" class="active-run" style="opacity: 0.5;"></div>
                                                </div>
                                                <div :class="`ico-slot ${item.iconClass}`"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="content-panel-coin">
                                    <div class="panel-coin">
                                        <div class="box-balance-coin">
                                            <div class="box-bonus-win">
                                                <small>Balance</small>
                                                <NumberBox :number="balance"></NumberBox>
                                            </div>
                                        </div>
                                        <div class="box-panel-play-tool">
                                            <div class="box-coin">
                                                <GameChipForSelect
                                                        v-for="(chip, i) of chipForSelectArr" :key="i"
                                                        :value="chip"
                                                        :active="amount === chip"
                                                        @click="selectChip(chip)">
                                                </GameChipForSelect>
                                                <!-- <div
                                                    class="GameChipForSelect b-coin c-5 active"
                                                ></div>
                                                <div
                                                    class="GameChipForSelect b-coin c-10"
                                                ></div>
                                                <div
                                                    class="GameChipForSelect b-coin c-50"
                                                ></div>
                                                <div
                                                    class="GameChipForSelect b-coin c-100"
                                                ></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-panel">
                            <div class="bottom-panel-info">
                                <div @click="clearBet" class="btnClear" :class="{'active': canClear}"></div>
                                <div class="btnNavi">
                                    <div @click="increaseGuessMiddnumAmount" class="btnNavi-left" :class="{'active': canMiddnum}"></div>
                                    <div @click="decreaseGuessMiddnumAmount" class="btnNavi-right" :class="{'active': canMiddnum}"></div>
                                </div>
                                <div class="btnHilo">
                                    <div @click="guessMiddnum('low')" class="btnHilo-hi" :class="{'active': canMiddnum}"></div>
                                    <div @click="guessMiddnum('high')" class="btnHilo-lo" :class="{'active': canMiddnum}"></div>
                                </div>
                                <div @click="play" class="btnGo click-run" :class="{'active': canPlay}"></div>
                            </div>
                        </div>
                        <div class="content-panel-score">
                            <div class="bottom-panel-score">

                                <div v-for="(betPanel, key) of betPanelArr" :key="key" class="bottom-panel-score-1">
                                    <div class="ico-action" :class="{'active-point': betPanel.isWin}"></div>
                                    <div v-if="betPanel.fixedMultiplier" class="top-num-score-1 red-score-1">
                                        {{betPanel.fixedMultiplier}}
                                    </div>
                                    <div v-else class="top-num-score-1" :class="{
                                        'active': isDynamicMultiplierActive(betPanel.dynamicMultiplier), 
                                        'blink-speed': isDynamicMultiplierActive(betPanel.dynamicMultiplier) && isHaveWin && middnumState !== 'spin'
                                        }">
                                        {{betPanel.multiplier}}
                                    </div>

                                    <div class="box-balance-coin">
                                        <div class="box-bonus-win">
                                            <span>
                                                <ul>
                                                    <li>{{getBetAmountByDigit(betPanel.amount, 0)}}</li>
                                                    <li>{{getBetAmountByDigit(betPanel.amount, 1)}}</li>
                                                    <li>{{getBetAmountByDigit(betPanel.amount, 2)}}</li>
                                                </ul>
                                            </span>
                                            <span class="bg">
                                                <ul>
                                                    <li>8</li>
                                                    <li>8</li>
                                                    <li>8</li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                    <div @click="selectBet(key)" :class="`btn-slot ${betPanel.iconClass}`"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import randomBetween from '@/lib/randomBetween'
import waitForSecond from '@/lib/waitForSecond'
import {mixin as VueTimers} from 'vue-timers'
import {
    BET_PANEL_ARR, 
    PLAY_PANEL_ARR, 
    ITEM_ARR, 
    DYNAMIC_MULTIPLIER_ARR, 
    AUDIO_SELECT_BET,
    AUDIO_WIN,
    AUDIO_ACTION,
    AUDIO_ONEMORE,
} from './Constant'
import NumberBox from './NumberBox'
import GameChipForSelect from '@/components/game/ChipForSelect'
import store from '@/store'
const ITEM_LENGTH = ITEM_ARR.length

export default {
    name: "MariSlotGame",
    mixins: [VueTimers],
    components: {
        NumberBox,
        GameChipForSelect,
    },
    timers: {
        activeIndexForwardSlow: {
            time: 200,
            repeat: true,
            immediate: true,
        },
        activeIndexForward: {
            time: 20,
            repeat: true,
        },
        spinMiddnum: {
            time: 50,
            repeat: true,
            immediate: true,
        },
        spinMiddnumSlow: {
            time: 400,
            repeat: true,
        },
        runDynamicMultiplierSlow: {
            time: 200,
            repeat: true,
            immediate: true,
        },
        runDynamicMultiplier: {
            time: 30,
            repeat: true,
        },
        runDynamicMultiplierBeforeEnd: {
            time: 200,
            repeat: true,
        }
    },
    data() {
        return {
            chipForSelectArr: [
                5, 10, 50, 100
            ],
            amount: 5,
            middnum: 0,
            middnumState: 'idle', //idle, spin, result,
            middnumResult: -1,
            middnumChoose: '', //high, low
            playPanelArr: [],
            betPanelArr: [],
            betPanelArrRecent: [],
            activeIndex: -1,
            activeIndexShadow1: -1,
            activeIndexShadow2: -1,
            activeIndexShadow3: -1,
            result: -1,
            resultData: null,
            extraResultRawArr: [],
            extraResultArr: [],
            gameState: 'idle', //idle, start-spin, spin-fast, end-spin-fast, before-result, result
            indexBeforeResult: -1,
            credit: 10000,
            bonusWin: 0,
            bonusWinDouble: 0,
            dynamicMultiplier: null,
            dynamicMultiplierResult: {},
            dynamicMultiplierActive: {
                left: -1,
                right: -1,
            },
            spinDirection: 'clockwise', //clockwise counter-clockwise
            spinMode: 'normal', //normal, onemore
            winSound: null,
            spinFastSound: null,
            onemoreRollSound: null,
        }
    },
    computed: {
        is_member() {
            return store.state.is_member
        },
        pes_profile() {
            return store.state.pes_profile
        },
        balance() {
            return (this.is_member) ? this.pes_profile.THB_free : 0 
        },
        middnumLeft() {
            const str = `${this.middnum}`
            if(str.length === 1) {
                return ""
            }
            return parseInt(str[0])
        },
        middnumRight() {
            const str = `${this.middnum}`
            return parseInt(str[str.length - 1])
        },
        isMiddnumBlink() {
            return (this.middnumState === 'result' && this.middnum > 0) ? 'blink' : ''
        },
        canClear() {
            return (this.totalAmount > 0 || this.betPanelArrRecent.length > 0) && 
            this.isGameIdle && 
            !this.isHaveWin && 
            !this.isResultOneMore(this.result)
        },
        totalAmount() {
            return this.betPanelArr.reduce((sum, item) => {
                return parseInt(sum) + parseInt(item.amount)
            }, 0)
        },
        canPlay() {
            return (this.totalAmount > 0 || this.betPanelArrRecent.length > 0) && 
            this.isGameIdle && 
            this.middnumState === 'idle' && 
            !this.isResultOneMore(this.result)
        },
        betList() {
            return this.betPanelArr.filter((betPanel) => {
                return parseInt(betPanel.amount) > 0
            })
        },
        isHaveWin() {
            if(!this.resultData) {
                return false
            }
            return this.gameState === 'result' && this.betList.length > 0 && !this.isResultOneMore(this.result)
        },
        canMiddnum() {
            return this.isHaveWin && this.middnumState === 'idle'
        },
        isGameIdle() {
            return this.gameState === 'idle' || this.gameState === 'result'
        },
        isGameBusy() {
            return !this.isGameIdle
        },
    },
    watch: {
        async activeIndex(newVal) {
            if(this.gameState === 'end-spin-fast' && parseInt(this.indexBeforeResult) === parseInt(newVal)) {
                this.stopSound(this.spinFastSound)
                this.playSound(AUDIO_ACTION.spinBeforeEnd)
                this.gameState = 'before-result'
                this.$timer.stop('activeIndexForward')
                this.$timer.start('activeIndexForwardSlow')
            } else if(this.gameState === 'before-result' && parseInt(this.result) === parseInt(newVal)) {

                if(this.extraResultRawArr.length > 0) {
                    this.gameState = 'extra'
                } else {
                    this.gameState = 'result'
                }
                
                this.$timer.stop('activeIndexForwardSlow')

                if(this.spinMode === 'onemore') {
                    this.stopSound(this.onemoreRollSound)
                    this.playSound(AUDIO_ONEMORE.bangOnResult)
                }

                if(this.resultData.slug === 'onemore' || this.resultData.slug === 'onemore2') {
                    
                    this.playSound(AUDIO_ONEMORE.beepBeforeRoll)
                    await waitForSecond(1000)

                    const isHaveOnemore = randomBetween(0, 1)
                    if(!isHaveOnemore) {
                        this.playSound(AUDIO_ONEMORE.noOnemore)
                        this.activeIndex = -1
                        this.result = -1
                        this.extraResultArr = []
                        this.resetGameState()
                        return
                    }

                    const spinDirection = (randomBetween(0, 1)) ? 'counter-clockwise': 'clockwise'
                    this.spinDirection = spinDirection
                    this.spinMode = 'onemore'
                    this.spin()
                    return
                }
                
                for(const extraResultRaw of this.extraResultRawArr) {
                    this.playSound(AUDIO_ACTION.beforeBonus)
                    await waitForSecond(2000)
                    this.extraResultArr.push(extraResultRaw)
                    this.playSound(AUDIO_ONEMORE.bangOnResult)
                    await waitForSecond(1000)
                    this.playSound(extraResultRaw.sound)
                }
                this.gameState = 'result'

                this.showBetPanelWin()
                if(this.isHaveWin) {
                    this.calBonusWin()
                    await waitForSecond(200)
                    if(this.resultData.sound) {
                        this.playSound(this.resultData.sound)
                    }
                    await waitForSecond(400)
                    const audioWinIndex = randomBetween(0, AUDIO_WIN.length - 1)
                    this.winSound = AUDIO_WIN[audioWinIndex]
                    this.playSound(this.winSound)
                } else {
                    this.resetGameState()
                }   
            }
        }
    },
    methods: {
        mod(n, m) {
            return ((n % m) + m) % m
        },
        getBetAmountByDigit(amount, i) {
            let str = `${amount}`
            const limit = 3
            const diff = limit - str.length
            for(let i = 0;i < diff;i++) {
                str = ' '+str
            }
            return str[i]
        },
        selectChip(chip) {
            this.playSound(AUDIO_ACTION.chipSwitch)
            this.amount = chip
        },
        async startSpinMiddnum() {

            await this.loadMiddnumResult()

            this.middnumState = 'spin'
            this.$timer.stop('spinMiddnum')
            this.$timer.start('spinMiddnum')
            setTimeout(() => {
                this.middnum = this.mod(this.middnumResult - 4, 13)
                this.$timer.stop('spinMiddnum')
                this.$timer.stop('spinMiddnumSlow')
                this.$timer.start('spinMiddnumSlow')
            }, 1600)
        },
        spinMiddnum() {
            this.playSound(AUDIO_ACTION.spinMiddnum)
            this.middnum = randomBetween(1, 13)
        },
        spinMiddnumSlow() {
            this.playSound(AUDIO_ACTION.spinMiddnum)
            this.middnum = Math.max(((this.middnum + 1) % 14), 1)
            if(parseInt(this.middnum) === parseInt(this.middnumResult)) {
                this.$timer.stop('spinMiddnumSlow')
                setTimeout(() => {
                    this.middnumState = 'result'
                    this.sumarizeMiddnum()
                }, 200)
            }
        },
        async sumarizeMiddnum() {
            if(this.middnumChoose === 'high' && this.middnumResult >= 8) {
                this.playSound(AUDIO_ACTION.guessMiddnumWin)
                await waitForSecond(1000)
                const audioWinIndex = randomBetween(0, AUDIO_WIN.length - 1)
                this.winSound = AUDIO_WIN[audioWinIndex]
                this.playSound(this.winSound)

                this.bonusWin *= 2
                this.bonusWinDouble = this.bonusWin * 2
            } else if(this.middnumChoose === 'low' && this.middnumResult <= 6) {
                this.playSound(AUDIO_ACTION.guessMiddnumWin)
                await waitForSecond(1000)
                const audioWinIndex = randomBetween(0, AUDIO_WIN.length - 1)
                this.winSound = AUDIO_WIN[audioWinIndex]
                this.playSound(this.winSound)

                this.bonusWin *= 2
                this.bonusWinDouble = this.bonusWin * 2
            } else {
                this.playSound(AUDIO_ACTION.guessMiddnumFail)
                
                this.bonusWin = 0
                this.bonusWinDouble = this.bonusWin * 2
                this.resetGameState()
            }
            await waitForSecond(2400)
            this.middnum = 0
            this.middnumState = 'idle'
        },
        increaseGuessMiddnumAmount() {
            if(!this.canMiddnum) {
                return
            }
            if(this.bonusWin < this.bonusWinDouble) {
                this.stopSound(this.winSound)
                this.playSound(AUDIO_ACTION.increaseGuessMiddnumAmount)
                const diff = this.bonusWinDouble - this.bonusWin
                if(diff > this.credit) {
                    return
                }
                this.bonusWin += diff
                this.credit -= diff
            }
        },
        decreaseGuessMiddnumAmount() {
            if(!this.canMiddnum) {
                return
            }
            if(this.bonusWin > 0) {
                this.stopSound(this.winSound)
                this.playSound(AUDIO_ACTION.decreaseGuessMiddnumAmount)
                this.bonusWin--
                this.credit++
                if(this.bonusWin === 0) {
                    this.resetGameState()
                }
            }
        },
        guessMiddnum(choose) {
            if(!this.canMiddnum) {
                return
            }
            this.stopSound(this.winSound)
            this.middnumChoose = choose
            this.startSpinMiddnum()
        },
        async loadMiddnumResult() {
            this.middnumResult = randomBetween(1, 13)
        },
        async loadResult() {
            this.result = randomBetween(0, ITEM_LENGTH - 1)
            this.resultData = ITEM_ARR[this.result]
            if(this.spinDirection === 'counter-clockwise') {
                this.indexBeforeResult = this.mod((this.result + 6), ITEM_LENGTH)
            } else {
                this.indexBeforeResult = this.mod((this.result - 6), ITEM_LENGTH)
            }
        },
        async loadDynamicMultiplierResult() {
            this.dynamicMultiplierResult.left = randomBetween(0, this.dynamicMultiplier.left.length - 1)
            this.dynamicMultiplierResult.right = randomBetween(0, this.dynamicMultiplier.right.length - 1)
        },
        async loadExtraResultArr() {
            this.extraResultRawArr = []
            const extraResultLength = randomBetween(1, 3)
            for(let i = 0;i < extraResultLength;i++) {
                const index = randomBetween(0, ITEM_LENGTH - 1)
                const foundIndex = this.extraResultRawArr.findIndex(extraResult => extraResult.i === index)
                if(!this.isResultOneMore(index) && index !== this.result && foundIndex === -1) {
                    this.extraResultRawArr.push(ITEM_ARR[index])
                }
            }
        },
        initPlayPanel() {
            this.playPanelArr = JSON.parse(JSON.stringify(PLAY_PANEL_ARR))
        },
        initBetPanel() {
            const betPanelArr = JSON.parse(JSON.stringify(BET_PANEL_ARR))
            for(const betPanel of betPanelArr) {
                this.betPanelArr.push(Object.assign(betPanel, {
                    amount: 0,
                }))
            }
        },
        initDynamicMultiplier() {
            this.dynamicMultiplier = JSON.parse(JSON.stringify(DYNAMIC_MULTIPLIER_ARR))
        },
        selectBet(i) {

            if(this.isGameBusy) {
                return
            }
            if(this.isHaveWin) {
                return
            }
            if(this.credit < this.amount) {
                return
            }

            this.playSound(AUDIO_SELECT_BET[i])

            this.credit -= this.amount
            this.betPanelArr[i].amount += this.amount
        },
        clearBet() {
            if(!this.canClear) {
                return
            }

            this.playSound(AUDIO_ACTION.clearBet)

            this.credit += this.totalAmount
            this.betPanelArr = []
            this.betPanelArrRecent = []
            this.initBetPanel()
        },
        clearResult() {
            this.extraResultRawArr = []
            this.extraResultArr = []
            this.middnumResult = -1
        },
        resetGameState() {
            this.gameState = 'idle'
            this.betPanelArr = []
            this.initBetPanel()
            this.stopSound(this.winSound)
        },
        async summarizeBonusWin() {

            this.playSound(AUDIO_ACTION.pushCoin)

            const amount = this.bonusWin
            const count = parseInt(amount / 100)
            const remain = amount % (Math.max(count, 1) * 100)
            if(count > 0) {
                for(let i = 0;i < 100;i++) {
                    await waitForSecond(10)
                    this.credit += count
                    this.bonusWin -= count
                }
            }
            for(let i = 0;i < remain;i++) {
                await waitForSecond(10)
                this.credit++
                this.bonusWin--
            }
        },
        calBonusWin() {

            let bonusWin = 0

            this.betList.map((bet) => {
                const winIndex = bet.winArr.findIndex((win) => {
                    return parseInt(win) === parseInt(this.resultData.i)
                })
                if(winIndex > -1) {
                    if(this.resultData.multiplier) {
                        bonusWin += this.resultData.multiplier * bet.amount
                    } else if(bet.fixedMultiplier) {
                        bonusWin += bet.fixedMultiplier * bet.amount
                    } else {
                        const side = bet.dynamicMultiplier.side
                        const multiplier = this.dynamicMultiplier[side][this.dynamicMultiplierResult[side]]
                        bonusWin += multiplier * bet.amount
                    }
                }
            })

            for(const extraResult of this.extraResultArr) {
                this.betList.map((bet) => {
                    const winIndex = bet.winArr.findIndex((win) => {
                        return parseInt(win) === parseInt(extraResult.i)
                    })
                    if(winIndex > -1) {
                        if(extraResult.multiplier) {
                            bonusWin += extraResult.multiplier * bet.amount
                        } else if(bet.fixedMultiplier) {
                            bonusWin += bet.fixedMultiplier * bet.amount
                        } else {
                            const side = bet.dynamicMultiplier.side
                            const multiplier = this.dynamicMultiplier[side][this.dynamicMultiplierResult[side]]
                            bonusWin += multiplier * bet.amount
                        }
                    }
                })
            }

            this.bonusWin = bonusWin
            this.bonusWinDouble = bonusWin * 2
        },
        showBetPanelWin() {

            for(const betPanel of this.betPanelArr) {

                let amount = 0

                if(betPanel.amount > 0) {
                    const winIndex = betPanel.winArr.findIndex((win) => {
                        return parseInt(win) === parseInt(this.resultData.i)
                    })
                    const isExtraWin = this.isExtraWin(betPanel.winArr)
                    if(winIndex > -1 || isExtraWin) {
                        amount = betPanel.amount
                        betPanel.isWin = true
                    }
                }

                betPanel.amount = amount
            }
        },
        async play() {

            if(!this.canPlay) {
                return
            }

            if(this.isHaveWin) {
                this.summarizeBonusWin()
                this.resetGameState()
                return
            }

            this.clearResult()

            if(this.totalAmount === 0 && this.betPanelArrRecent.length > 0) {
                for(let i = 0;i < this.betPanelArr.length;i++) {
                    this.betPanelArr[i].amount = this.betPanelArrRecent[i].amount
                }
                if(this.totalAmount > this.credit) {
                    this.betPanelArrRecent = []
                    this.resetGameState()
                    return
                }
                this.credit -= this.totalAmount
            }
            
            this.betPanelArrRecent = JSON.parse(JSON.stringify(this.betPanelArr))

            this.spinDirection = 'clockwise'
            this.spinMode = 'normal'

            this.spin()
        },
        async spin() {

            await this.loadResult()
            await this.loadDynamicMultiplierResult()

            const randToLoadExtraResult = randomBetween(1, 10)
            if(randToLoadExtraResult === 1) {
                await this.loadExtraResultArr()
            }

            if(this.spinMode === 'normal') {

                this.playSound(AUDIO_ACTION.spinStart)

                this.gameState = 'start-spin'

                this.$timer.start('activeIndexForwardSlow')
                this.$timer.start('runDynamicMultiplierSlow')

                await waitForSecond(1000)

                this.spinFastSound = AUDIO_ACTION.spinFast
                this.playSound(this.spinFastSound)
                this.gameState = 'spin-fast'
                this.$timer.stop('activeIndexForwardSlow')
                this.$timer.stop('runDynamicMultiplierSlow')
                this.$timer.start('activeIndexForward')
                this.$timer.start('runDynamicMultiplier')

                await waitForSecond(1430)

                this.gameState = 'end-spin-fast'
                this.$timer.stop('runDynamicMultiplier')
                this.$timer.start('runDynamicMultiplierSlow')

                await waitForSecond(800)

                this.$timer.stop('runDynamicMultiplierSlow')
                this.$timer.start('runDynamicMultiplierBeforeEnd')

            } else if(this.spinMode === 'onemore') {

                this.onemoreRollSound = AUDIO_ONEMORE.roll
                this.playSound(this.onemoreRollSound)
                this.gameState = 'before-result'
                this.$timer.start('activeIndexForwardSlow')
                await waitForSecond(1000)
            }
        },
        activeIndexForwardSlow() {
            const manipulation = (this.spinDirection === 'clockwise') ? 1 : -1
            this.activeIndex = this.mod((this.activeIndex + manipulation), ITEM_LENGTH)
            this.activeIndexShadow1 = -1
            this.activeIndexShadow2 = -1
            this.activeIndexShadow3 = -1
        },
        activeIndexForward() {
            const manipulation = (this.spinDirection === 'clockwise') ? 1 : -1
            this.activeIndex = this.mod((this.activeIndex + manipulation), ITEM_LENGTH)
            this.activeIndexShadow1 = this.mod((this.activeIndex - 1), ITEM_LENGTH)
            this.activeIndexShadow2 = this.mod((this.activeIndex - 2), ITEM_LENGTH)
            this.activeIndexShadow3 = this.mod((this.activeIndex - 3), ITEM_LENGTH)
        },
        runDynamicMultiplier() {
            this.dynamicMultiplierActive.left = this.mod(this.dynamicMultiplierActive.left + 1, this.dynamicMultiplier.left.length)
            this.dynamicMultiplierActive.right = this.mod(this.dynamicMultiplierActive.right + 1, this.dynamicMultiplier.right.length)
        },
        runDynamicMultiplierSlow() {
            this.dynamicMultiplierActive.left = this.mod(this.dynamicMultiplierActive.left + 1, this.dynamicMultiplier.left.length)
            this.dynamicMultiplierActive.right = this.mod(this.dynamicMultiplierActive.right + 1, this.dynamicMultiplier.right.length)
        },
        runDynamicMultiplierBeforeEnd() {
            if(this.dynamicMultiplierResult.left !== this.dynamicMultiplierActive.left){
                this.dynamicMultiplierActive.left = this.mod(this.dynamicMultiplierActive.left + 1, this.dynamicMultiplier.left.length)
            }
            if(this.dynamicMultiplierResult.right !== this.dynamicMultiplierActive.right){
                this.dynamicMultiplierActive.right = this.mod(this.dynamicMultiplierActive.right + 1, this.dynamicMultiplier.right.length)
            }
            if(this.dynamicMultiplierResult.left === this.dynamicMultiplierActive.left && 
            this.dynamicMultiplierResult.right === this.dynamicMultiplierActive.right) {
                this.$timer.stop('runDynamicMultiplierBeforeEnd')
            }
        },
        isItemResult(i) {
            return this.isGameIdle && parseInt(this.result) === parseInt(i)
        },
        isDynamicMultiplierActive(dynamicMultiplier) {
            if(this.middnumResult >= 0) {
                if(dynamicMultiplier.side === 'left') {
                    return this.middnumChoose === 'low'
                } else if(dynamicMultiplier.side === 'right') {
                    return this.middnumChoose === 'high'
                }
            } else {
                if(dynamicMultiplier.side === 'left') {
                    return this.dynamicMultiplierActive.left === dynamicMultiplier.index
                } else if(dynamicMultiplier.side === 'right') {
                    return this.dynamicMultiplierActive.right === dynamicMultiplier.index
                }
            }
        },
        isItemInExtra(i) {
            const foundIndex = this.extraResultArr.findIndex(extraResult => parseInt(extraResult.i) === parseInt(i))
            return (this.isGameIdle || this.gameState === 'extra') && !this.isResultOneMore(this.result) && foundIndex > -1
        },
        isResultOneMore(i) {
            return i === 9 || i === 21
        },
        isExtraWin(winArr) {
            let isExtraWin = false
            for(const extraResult of this.extraResultArr) {
                const extraWinIndex = winArr.findIndex((win) => {
                    return parseInt(win) === parseInt(extraResult.i)
                })
                if(extraWinIndex > -1) {
                    isExtraWin = true
                    break
                }
            }
            return isExtraWin
        },
        playSound(sound) {
            if(!sound) {
                return
            }
            sound.play()
        },
        stopSound(sound) {
            if(!sound) {
                return
            }
            sound.stop()
        },
    },
    mounted() {
        this.initPlayPanel()
        this.initBetPanel()
        this.initDynamicMultiplier()
    },
};
</script>
<style src="@/assets/css/mari-slot.css" scoped></style>
<style scoped>

    @font-face {
        font-family: 'ds-digitalnormal';
        src: url("../../assets/fonts/ds_digital/ds-digit-webfont.eot");
        src: url("../../assets/fonts/ds_digital/ds-digit-webfont.svg");
        src: url("../../assets/fonts/ds_digital/ds-digit-webfont.woff");
        src: url("../../assets/fonts/ds_digital/ds-digit-webfont.woff2");
    }

    /*.bg-overlay {*/
    /*    position: fixed;*/
    /*    width: 100%;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*    height: 100%;*/
    /*    z-index: 999;*/
    /*    !*background-color: #fff;*!*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/


</style>
<style>
    .wrap-menu-top{
        /*background-image: linear-gradient(*/
        /*        135deg*/
        /*        , #F372D4 10%, #c765f0 100%);*/
    }
    .box-img {
        z-index: 1;
    }
</style>