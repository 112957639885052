import {Howl} from 'howler';

export const BET_PANEL_ARR = [
    {
        'slug': 'bar',
        'iconClass': 'btn-bar',
        'fixedMultiplier': 100,
        'winArr': [2, 3],
    },
    {
        'slug': '77',
        'iconClass': 'btn-77',
        'fixedMultiplier': null,
        'multiplier': 40,
        'winArr': [15],
        'dynamicMultiplier': {
            'side': 'left',
            'index': 0,
        },
    },
    {
        'slug': 'star',
        'iconClass': 'btn-star',
        'fixedMultiplier': null,
        'multiplier': 30,
        'winArr': [23],
        'dynamicMultiplier': {
            'side': 'left',
            'index': 1,
        },
    },
    {
        'slug': 'watermelon',
        'iconClass': 'btn-watermelon',
        'fixedMultiplier': null,
        'multiplier': 20,
        'winArr': [6],
        'dynamicMultiplier': {
            'side': 'left',
            'index': 2,
        },
    },
    {
        'slug': 'bell',
        'iconClass': 'btn-bell',
        'fixedMultiplier': null,
        'multiplier': 20,
        'winArr': [1, 13],
        'dynamicMultiplier': {
            'side': 'right',
            'index': 0,
        },
    },
    {
        'slug': 'grape',
        'iconClass': 'btn-grape',
        'fixedMultiplier': null,
        'multiplier': 15,
        'winArr': [7, 18],
        'dynamicMultiplier': {
            'side': 'right',
            'index': 1,
        },
    },
    {
        'slug': 'orange',
        'iconClass': 'btn-orange',
        'fixedMultiplier': null,
        'multiplier': 10,
        'winArr': [0, 12],
        'dynamicMultiplier': {
            'side': 'right',
            'index': 2,
        },
    },
    {
        'slug': 'cherry',
        'iconClass': 'btn-cherry',
        'fixedMultiplier': 2,
        'winArr': [5, 8, 11, 14, 17, 19, 22],
    },
    {
        'slug': 'apple',
        'iconClass': 'btn-apple',
        'fixedMultiplier': 5,
        'winArr': [4, 10, 16, 20],
    },
]

export const DYNAMIC_MULTIPLIER_ARR = {
    'left': [
        40,
        30,
        20,
    ],
    'right': [
        20,
        15,
        10,
    ],
}

export const PLAY_PANEL_ARR = [
    {
        'slug': 'top',
        'rowClass': 'top-row-slot',
        'itemArr': [
            {
                'i': 0,
                'slug': 'orange',
                'areaClass': 'area-top box-1',
                'iconClass': 'bx-orange',
            },
            {
                'i': 1,
                'slug': 'bell',
                'areaClass': 'area-top box-2',
                'iconClass': 'bx-bell',
            },
            {
                'i': 2,
                'slug': '50bar',
                'areaClass': 'area-top box-3',
                'iconClass': 'bx-50bar',
            },
            {
                'i': 3,
                'slug': '100bar',
                'areaClass': 'area-top box-4',
                'iconClass': 'bx-100bar',
            },
            {
                'i': 4,
                'slug': 'apple',
                'areaClass': 'area-top box-5',
                'iconClass': 'bx-apple',
            },
            {
                'i': 5,
                'slug': 'cherry',
                'areaClass': 'area-top box-6',
                'iconClass': 'bx-cherry',
            },
            {
                'i': 6,
                'slug': 'watermelon',
                'areaClass': 'area-top box-7',
                'iconClass': 'bx-watermelon',
            },
        ],
    },
    {
        'slug': 'right',
        'rowClass': 'right-row-slot',
        'itemArr': [
            {
                'i': 7,
                'slug': 'grape',
                'areaClass': 'area-right box-8',
                'iconClass': 'bx-grape',
            },
            {
                'i': 8,
                'slug': 'cherry',
                'areaClass': 'area-right box-9',
                'iconClass': 'bx-cherry',
            },
            {
                'i': 9,
                'slug': 'onemore',
                'areaClass': 'area-right box-10',
                'iconClass': 'bx-onemore',
            },
            {
                'i': 10,
                'slug': 'apple',
                'areaClass': 'area-right box-11',
                'iconClass': 'bx-apple',
            },
            {
                'i': 11,
                'slug': 'cherry',
                'areaClass': 'area-right box-12',
                'iconClass': 'bx-cherry',
            },
        ],
    },
    {
        'slug': 'bottom',
        'rowClass': 'bottom-row-slot',
        'itemArr': [
            {
                'i': 18,
                'slug': 'grape',
                'areaClass': 'area-bottom box-19',
                'iconClass': 'bx-grape',
            },
            {
                'i': 17,
                'slug': 'cherry',
                'areaClass': 'area-bottom box-18',
                'iconClass': 'bx-cherry',
            },
            {
                'i': 16,
                'slug': 'apple',
                'areaClass': 'area-bottom box-17',
                'iconClass': 'bx-apple',
            },
            {
                'i': 15,
                'slug': '77',
                'areaClass': 'area-bottom box-16',
                'iconClass': 'bx-77icon',
            },
            {
                'i': 14,
                'slug': 'cherry',
                'areaClass': 'area-bottom box-15',
                'iconClass': 'bx-cherry',
            },
            {
                'i': 13,
                'slug': 'bell',
                'areaClass': 'area-bottom box-14',
                'iconClass': 'bx-bell',
            },
            {
                'i': 12,
                'slug': 'orange',
                'areaClass': 'area-bottom box-13',
                'iconClass': 'bx-orange',
            },
        ],
    },
    {
        'slug': 'left',
        'rowClass': 'left-row-slot',
        'itemArr': [
            {
                'i': 23,
                'slug': 'star',
                'areaClass': 'area-left box-20',
                'iconClass': 'bx-star',
            },
            {
                'i': 22,
                'slug': 'cherry',
                'areaClass': 'area-left box-21',
                'iconClass': 'bx-cherry',
            },
            {
                'i': 21,
                'slug': 'onemore2',
                'areaClass': 'area-left box-22',
                'iconClass': 'bx-onemore2',
            },
            {
                'i': 20,
                'slug': 'apple',
                'areaClass': 'area-left box-23',
                'iconClass': 'bx-apple',
            },
            {
                'i': 19,
                'slug': 'cherry',
                'areaClass': 'area-left box-24',
                'iconClass': 'bx-cherry',
            },
        ],
    },
]

export const ITEM_ARR = [
    {
        'i': 0,
        'slug': 'orange',
        'areaClass': 'area-top box-1',
        'iconClass': 'bx-orange',
        'sound': new Howl({src: ['/sounds/mari-slot/result/orange-Y102_e.ogg']}),
    },
    {
        'i': 1,
        'slug': 'bell',
        'areaClass': 'area-top box-2',
        'iconClass': 'bx-bell',
        'sound': new Howl({src: ['/sounds/mari-slot/result/bell-Y104_e.ogg']}),
    },
    {
        'i': 2,
        'slug': '50bar',
        'areaClass': 'area-top box-3',
        'iconClass': 'bx-50bar',
        'multiplier': 50,
        'sound': new Howl({src: ['/sounds/mari-slot/result/bar-Y108_e.ogg']}),
    },
    {
        'i': 3,
        'slug': '100bar',
        'areaClass': 'area-top box-4',
        'iconClass': 'bx-100bar',
        'multiplier': 100,
        'sound': new Howl({src: ['/sounds/mari-slot/result/bar-Y108_e.ogg']}),
    },
    {
        'i': 4,
        'slug': 'apple',
        'areaClass': 'area-top box-5',
        'iconClass': 'bx-apple',
        'sound': new Howl({src: ['/sounds/mari-slot/result/apple-Y101_e.ogg']}),
    },
    {
        'i': 5,
        'slug': 'cherry',
        'areaClass': 'area-top box-6',
        'iconClass': 'bx-cherry',
        'sound': new Howl({src: ['/sounds/mari-slot/result/awesome-Y109_e.ogg']}),
    },
    {
        'i': 6,
        'slug': 'watermelon',
        'areaClass': 'area-top box-7',
        'iconClass': 'bx-watermelon',
        'sound': new Howl({src: ['/sounds/mari-slot/result/watermelon-Y105_e.ogg']}),
    },
    {
        'i': 7,
        'slug': 'grape',
        'areaClass': 'area-right box-8',
        'iconClass': 'bx-grape',
        'sound': new Howl({src: ['/sounds/mari-slot/result/excellent-Y110_e.ogg']}),
    },
    {
        'i': 8,
        'slug': 'cherry',
        'areaClass': 'area-right box-9',
        'iconClass': 'bx-cherry',
        'sound': new Howl({src: ['/sounds/mari-slot/result/awesome-Y109_e.ogg']}),
    },
    {
        'i': 9,
        'slug': 'onemore',
        'areaClass': 'area-right box-10',
        'iconClass': 'bx-onemore',
    },
    {
        'i': 10,
        'slug': 'apple',
        'areaClass': 'area-right box-11',
        'iconClass': 'bx-apple',
        'sound': new Howl({src: ['/sounds/mari-slot/result/apple-Y101_e.ogg']}),
    },
    {
        'i': 11,
        'slug': 'cherry',
        'areaClass': 'area-right box-12',
        'iconClass': 'bx-cherry',
        'sound': new Howl({src: ['/sounds/mari-slot/result/awesome-Y109_e.ogg']}),
    },
    {
        'i': 12,
        'slug': 'orange',
        'areaClass': 'area-bottom box-13',
        'iconClass': 'bx-orange',
        'sound': new Howl({src: ['/sounds/mari-slot/result/orange-Y102_e.ogg']}),
    },
    {
        'i': 13,
        'slug': 'bell',
        'areaClass': 'area-bottom box-14',
        'iconClass': 'bx-bell',
        'sound': new Howl({src: ['/sounds/mari-slot/result/bell-Y104_e.ogg']}),
    },
    {
        'i': 14,
        'slug': 'cherry',
        'areaClass': 'area-bottom box-15',
        'iconClass': 'bx-cherry',
        'sound': new Howl({src: ['/sounds/mari-slot/result/awesome-Y109_e.ogg']}),
    },
    {
        'i': 15,
        'slug': '77',
        'areaClass': 'area-bottom box-16',
        'iconClass': 'bx-77icon',
        'sound': new Howl({src: ['/sounds/mari-slot/result/77-Y107_e.ogg']}),
    },
    {
        'i': 16,
        'slug': 'apple',
        'areaClass': 'area-bottom box-17',
        'iconClass': 'bx-apple',
        'sound': new Howl({src: ['/sounds/mari-slot/result/apple-Y101_e.ogg']}),
    },
    {
        'i': 17,
        'slug': 'cherry',
        'areaClass': 'area-bottom box-18',
        'iconClass': 'bx-cherry',
        'sound': new Howl({src: ['/sounds/mari-slot/result/awesome-Y109_e.ogg']}),
    },
    {
        'i': 18,
        'slug': 'grape',
        'areaClass': 'area-bottom box-19',
        'iconClass': 'bx-grape',
        'sound': new Howl({src: ['/sounds/mari-slot/result/excellent-Y110_e.ogg']}),
    },
    {
        'i': 19,
        'slug': 'cherry',
        'areaClass': 'area-left box-24',
        'iconClass': 'bx-cherry',
        'sound': new Howl({src: ['/sounds/mari-slot/result/awesome-Y109_e.ogg']}),
    },
    {
        'i': 20,
        'slug': 'apple',
        'areaClass': 'area-left box-23',
        'iconClass': 'bx-apple',
        'sound': new Howl({src: ['/sounds/mari-slot/result/apple-Y101_e.ogg']}),
    },
    {
        'i': 21,
        'slug': 'onemore2',
        'areaClass': 'area-left box-22',
        'iconClass': 'bx-onemore2',
    },
    {
        'i': 22,
        'slug': 'cherry',
        'areaClass': 'area-left box-21',
        'iconClass': 'bx-cherry',
        'sound': new Howl({src: ['/sounds/mari-slot/result/awesome-Y109_e.ogg']}),
    },
    {
        'i': 23,
        'slug': 'star',
        'areaClass': 'area-left box-20',
        'iconClass': 'bx-star',
        'sound': new Howl({src: ['/sounds/mari-slot/result/star-Y106_e.ogg']}),
    },
]

export const AUDIO_SELECT_BET = [
    new Howl({src: ['/sounds/mari-slot/select-bet/Y201.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y201.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y202.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y203.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y204.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y205.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y206.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y207.ogg']}),
    new Howl({src: ['/sounds/mari-slot/select-bet/Y208.ogg']}),
]
export const AUDIO_WIN = [
    new Howl({src: ['/sounds/mari-slot/win/C01.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C02.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C03.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C04.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C05.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C06.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C07.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C08.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C09.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C10.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C11.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C12.ogg']}),
    new Howl({src: ['/sounds/mari-slot/win/C13.ogg']}),
]
export const AUDIO_ACTION = {
    'clearBet': new Howl({src: ['/sounds/mari-slot/clear-Y211.ogg']}),
    'increaseGuessMiddnumAmount': new Howl({src: ['/sounds/mari-slot/clear-Y211.ogg']}),
    'decreaseGuessMiddnumAmount': new Howl({src: ['/sounds/mari-slot/clear-Y211.ogg']}),
    'spinMiddnum':  new Howl({src: ['/sounds/mari-slot/middnum-run-hit.ogg']}),
    'guessMiddnumWin':  new Howl({src: ['/sounds/mari-slot/middnum-win-Y008.ogg']}),
    'guessMiddnumFail':  new Howl({src: ['/sounds/mari-slot/middnum-fail-Y016.ogg']}),
    'pushCoin': new Howl({src: ['/sounds/mari-slot/push_coin.ogg']}),
    'spinStart': new Howl({src: ['/sounds/mari-slot/spin-start-Y021.ogg']}),
    'spinFast': new Howl({src: ['/sounds/mari-slot/spin-fast-Y029.ogg'], loop: true,}),
    'spinBeforeEnd': new Howl({src: ['/sounds/mari-slot/spin-before-end-Y030.ogg']}),
    'chipSwitch': new Howl({src: ['/sounds/mari-slot/chip_switch.ogg']}),
    'beforeBonus': new Howl({src: ['/sounds/mari-slot/before-bonus-Y008.ogg']})
}
export const AUDIO_ONEMORE = {
    'bangOnResult': new Howl({src: ['/sounds/mari-slot/onemore/bang-on-result-Y001.ogg']}),
    'beepBeforeRoll': new Howl({src: ['/sounds/mari-slot/onemore/beep-before-roll-Y006.ogg']}),
    'noOnemore': new Howl({src: ['/sounds/mari-slot/onemore/no-onemore-Y016.ogg']}),
    'roll': new Howl({src: ['/sounds/mari-slot/onemore/roll-Y007.ogg'], loop: true}),
}